import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import MockService from "@/core/mock/mock.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import moment from "moment";
import "moment-duration-format";
import "moment/locale/ro";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import Raphael from "raphael/raphael";
window.Raphael = Raphael;
import "bootstrap/js/src";
import "bootstrap-autocomplete";
import "bootstrap-notify";

// Vue 3rd party plugins
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/vuelayers";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

moment.locale("ro");
moment.relativeTimeThreshold('s', 45);
moment.relativeTimeThreshold('ss', 29);
moment.relativeTimeThreshold('m', 45);
moment.relativeTimeThreshold('h', 22);
moment.relativeTimeThreshold('d', 5);
moment.relativeTimeThreshold('w', 3);
moment.relativeTimeThreshold('M', 11);

// Remove this to disable mock API
MockService.init();

let authVerified = false;

router.beforeEach(async (to, from, next) => {
  if (!authVerified) {
    authVerified = true;

    await store.dispatch("checkAuthenticated");
  }

  if (!store.getters.isAuthenticated) {
    if (to.matched.every(record => !record.meta.anonymous)) {
      next({ name: "login" });
      return;
    }
  }

  next();

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
