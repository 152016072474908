import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiOrdersService {
  /**
   * Creates a new instance of the API stations service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Loads / searches for orders, paginated.
   *
   * @returns Response with matching orders & pagination info.
   */
  async list(start, length, search) {
    try {
      const params   = { start, length, search };
      const response = await this.axios.get("orders/", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea comenzilor.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves the specified order.
   *
   * @param id The ID of the order to retrieve.
   * @returns The retrieved order.
   */
  async get(id) {
    try {
      const response = await this.axios.get(`orders/${id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea comenzii.");

      throw newApiError(error);
    }
  }
}
