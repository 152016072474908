import ApiError from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiAuthService {
  /**
   * Creates a new instance of the API authentication service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Logs in the user with the specified username & password.
   * @param {String} username The user's username.
   * @param {String} password The user's password.
   * @param {Boolean} persistent Whether to keep the session persistent (remember-me).
   */
  async login(username, password, persistent) {
    persistent = !!persistent;

    try {
      const response = await this.axios.post("auth/login", { username, password, persistent });

      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Logs out the currently logged-in user.
   */
  async logout() {
    try {
      await this.axios.post("auth/logout");
      await this.hub.stop();
    } catch (error) {
      try {
        await this.hub.stop();
      } catch (error2) {
        // Something is badly broken (no connection?), ignore the second error.
      }

      throw new ApiError(error);
    }
  }

  /**
   * Gets the currently logged-in user.
   *
   * @returns Currently logged-in user profile.
   */
  async getLoggedInUser() {
    try {
      const response = await this.axios.get("auth/loggedin");

      return response.data;
    } catch (error) {
      const status = error.code || error.response.status;

      if (status === 401) {
        return null;
      } else {
        throw new ApiError(error);
      }
    }
  }
}
