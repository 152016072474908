import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  routes: [
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard/Dashboard.vue"),
        },
        {
          path: "/adrese",
          name: "addresses",
          component: () => import("@/view/pages/addresses/Addresses.vue"),
        },
        {
          path: "/cars",
          name: "cars",
          component: () => import("@/view/pages/cars/Cars.vue"),
        },
        {
          path: "/clienti",
          name: "customers",
          component: () => import("@/view/pages/customers/Customers.vue"),
        },
        {
          path: "/comenzi",
          name: "orders",
          component: () => import("@/view/pages/orders/Orders.vue"),
        },
        {
          path: "/setari",
          name: "settings",
          component: () => import("@/view/pages/settings/Settings.vue"),
        },
        {
          path: "/statii",
          name: "stations",
          component: () => import("@/view/pages/stations/Stations.vue"),
        },
        {
          path: "/zone",
          name: "zones",
          component: () => import("@/view/pages/zones/Zones.vue"),
        },
        {
          path: "/statii/:id/rezerve",
          name: "station-fallback",
          props: true,
          component: () => import("@/view/pages/stations/fallback/Fallback.vue"),
        }
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/auth/Login.vue"),
      meta: {
        anonymous: true,
      },
    },
    {
      path: "*",
      redirect: "/404",
      meta: {
        anonymous: true,
      },
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/404.vue"),
      meta: {
        anonymous: true,
      },
    }
  ],
});
