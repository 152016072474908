import api from "@/core/services/api";

export default {
  state: {
    user: null,
    status: null,
  },

  getters: {
    currentUser: state => state.user,

    isAuthenticated: state => !!state.user,
  },

  mutations: {
    authLoading(state) {
      state.error  = null;
      state.status = "loading";
    },

    authSuccess(state, user) {
      state.user   = user;
      state.error  = null;
      state.status = "success";
    },

    authError(state, error) {
      state.user   = null;
      state.error  = error;
      state.status = "error";
    },

    logout(state) {
      state.user   = null;
      state.error  = null;
      state.status = null;
    },
  },

  actions: {
    async login({ commit }, { username, password, remember }) {
      commit("authLoading");

      try {
        const user = await api.auth.login(username, password, remember);

        commit("authSuccess", user);
      } catch (error) {
        commit("authError", error);

        throw error;
      }
    },

    async logout({ commit }) {
      try {
        await api.auth.logout();
      } catch (error) {
        // ignored
      } finally {
        commit("logout");
      }
    },

    async checkAuthenticated({ commit }) {
      commit("authLoading");

      try {
        const user = await api.auth.getLoggedInUser();

        if (user) {
          commit("authSuccess", user);
        } else {
          commit("logout");
        }
      } catch (error) {
        commit("authError", error);
      }
    },
  },
};
