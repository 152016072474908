import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiStationsService {
  /**
   * Creates a new instance of the API stations service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Loads all stations.
   *
   * @returns List of all stations.
   */
  async list() {
    try {
      const response = await this.axios.get("stations/");

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea stațiilor.");

      throw newApiError(error);
    }
  }

  /**
   * Creates the station.
   * @param station The station to create
   * @returns The created station.
   */
  async create(station) {
    try {
      const response = await this.axios.post("stations/", station);

      return response.data;
    } catch (error) {
      notify.error("Eroare la salvarea stației.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves the specified station.
   * @param id The ID of the station to retrieve.
   * @returns The retrieved station.
   */
  async get(id) {
    try {
      const response = await this.axios.get(`stations/${id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea stației.");

      throw newApiError(error);
    }
  }

  /**
   * Updates the specified station.
   * @param station The station to update.
   * @returns The updated station.
   */
  async update(station) {
    try {
      const response = await this.axios.put(`stations/${station.id}`, station);

      return response.data;
    } catch (error) {
      notify.error("Eroare la salvarea stației.");

      throw newApiError(error);
    }
  }

  /**
   * Deleted the specified station.
   * @param station The station to delete.
   */
  async delete(station) {
    try {
      const response = await this.axios.delete(`stations/${station.id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la ștergerea stației.");

      throw newApiError(error);
    }
  }
}
