import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiAddressesService {
  /**
   * Creates a new instance of the API addresses service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Loads all addresses.
   * @param {String} withinWkt The shape within which to load the addresses (eg. the map extent as a polygon)
   * @returns List of all addresses with the specified shape.
   */
  async list(withinWkt) {
    try {
      const params   = { within: withinWkt };
      const response = await this.axios.get("addresses/", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea adreselor.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves (& saves) the address for the specified location.
   * @param {Number} latitude The location's latitude.
   * @param {Number} longitude The location's longitude.
   */
  async create(latitude, longitude) {
    try {
      const params   = { latitude, longitude };
      const response = await this.axios.post("addresses", null, { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Updates the specified address.
   * @param {String} geohash The address' geohash.
   * @param {Object} address The updated address.
   */
  async update(geohash, address) {
    try {
      const response = await this.axios.put(`addresses/${geohash}`, address);

      return response.data;
    } catch (error) {
      notify.error("Eroare la salvarea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Deletes the specified address.
   * @param {String} geohash The address' geohash.
   */
  async delete(geohash, address) {
    try {
      await this.axios.delete(`addresses/${geohash}`);
    } catch (error) {
      notify.error("Eroare la ștergerea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Returns the location for the specified address.
   * @param address The address to geocode.
   * @returns The geocoded location, or null if not found.
   */
  async geocode(address) {
    try {
      const params   = address;
      const response = await this.axios.get("addresses/geocode", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Returns the address for the specified location.
   * @param {Number} latitude The location's latitude.
   * @param {Number} longitude The location's longitude.
   */
  async reverseGeocode(latitude, longitude) {
    try {
      const params   = { latitude, longitude };
      const response = await this.axios.get("addresses/geocode/reverse", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a landmark.
   * @param {String} terms The landmark search terms.
   */
  async searchLandmark(terms) {
    try {
      const params   = { q: terms };
      const response = await this.axios.get("addresses/landmarks", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la căutarea punctului de interes.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a zone.
   * @param {String} terms The zone search terms.
   */
  async searchZone(terms) {
    try {
      const params   = { q: terms };
      const response = await this.axios.get("addresses/zones", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la căutarea cartierului.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a street.
   * @param {String} terms The street search terms.
   */
  async searchStreet(terms) {
    try {
      const params   = { q: terms };
      const response = await this.axios.get("addresses/streets", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la căutarea străzii.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a building.
   * @param {String} terms The building search terms.
   * @param {String} zone The zone to search in.
   * @param {String} street The street to search on.
   */
  async searchBuildingNo(terms, zone, street) {
    try {
      const params   = { q: terms, zone, street };
      const response = await this.axios.get("addresses/buildings", { params });

      return response.data;
    } catch (error) {
      notify.error("Eroare la căutarea blocului.");

      throw newApiError(error);
    }
  }
}
