import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";
import { buildRange, parseRange } from "./api.ranges";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiCustomersService {
  /**
   * Creates a new instance of the API stations service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Loads / searches for customers, paginated.
   *
   * @returns Response with matching customers & pagination info.
   */
  async list(offset, limit, search, sort) {
    try {
      const params   = { search, sort };
      const range    = buildRange(offset, limit);
      const response = await this.axios.get("customers", { params, headers: { range } });

      return {
        range: parseRange(response),
        results: response.data,
      };
    } catch (error) {
      if (error.response && error.response.status === 416) {
        return {
          range: parseRange(error.response),
          results: [],
        };
      }

      notify.error("Eroare la încărcarea clienților.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves the specified customer.
   *
   * @param id The ID of the customer to retrieve.
   * @returns The retrieved customer.
   */
  async get(id) {
    try {
      const response = await this.axios.get(`customers/${id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Updated the specified customer.
   *
   * @param id The ID of the customer to update.
   * @param patch The patch to apply to the customer.
   * @returns The updated customer.
   */
  async patch(id, patch) {
    try {
      const response = await this.axios.patch(`customers/${id}`, patch);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Enabled the specified customer.
   * @param {String} id The ID of the customer to enable.
   */
  async enable(id) {
    try {
      const response = await this.axios.put(`customers/${encodeURIComponent(id)}/enabled`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la activarea clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Disables the specified customer.
   * @param {String} id The ID of the customer to disable.
   */
  async disable(id) {
    try {
      const response = await this.axios.delete(`customers/${encodeURIComponent(id)}/enabled`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la dezactivarea clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Suspends the specified customer for a limited duration.
   * @param {String} id The ID of the customer to suspend.
   * @param {String} duration The time for which to suspend the customer.
   * @param {String} reason The reason for suspension.
   */
  async suspend(id, duration, reason) {
    try {
      await this.axios.put(`customers/${encodeURIComponent(id)}/suspension`, { duration, reason });
    } catch (error) {
      notify.error("Eroare la blocarea clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Cancels the specified customer's suspension.
   * @param {String} id The ID of the customer to reinstate.
   */
  async reinstate(id) {
    try {
      await this.axios.delete(`customers/${encodeURIComponent(id)}/suspension`);
    } catch (error) {
      notify.error("Eroare la deblocarea clientului.");

      throw newApiError(error);
    }
  }
}
