import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiZonesService {
  /**
   * Creates a new instance of the API zones service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Loads all zones.
   *
   * @returns List of all zones.
   */
  async list() {
    try {
      const response = await this.axios.get("zones/");

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea zonelor.");

      throw newApiError(error);
    }
  }

  /**
   * Creates the zone.
   * @param zone The zone to create
   * @returns The created zone.
   */
  async create(zone) {
    try {
      const response = await this.axios.post("zones/", zone);

      return response.data;
    } catch (error) {
      notify.error("Eroare la salvarea zonei.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves the specified zone.
   * @param id The ID of the zone to retrieve.
   * @returns The retrieved zone.
   */
  async get(id) {
    try {
      const response = await this.axios.get(`zones/${id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea zonei.");

      throw newApiError(error);
    }
  }

  /**
   * Updates the specified zone.
   * @param zone The zone to update.
   * @returns The updated zone.
   */
  async update(zone) {
    try {
      const response = await this.axios.put(`zones/${zone.id}`, zone);

      return response.data;
    } catch (error) {
      notify.error("Eroare la salvarea zonei.");

      throw newApiError(error);
    }
  }

  /**
   * Deleted the specified zone.
   * @param zone The zone to delete.
   */
  async delete(zone) {
    try {
      const response = await this.axios.delete(`zones/${zone.id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la ștergerea zonei.");

      throw newApiError(error);
    }
  }
}
