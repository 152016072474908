import axios from "axios";

import { ApiAddressesService } from "./api.addresses.service";
import { ApiAuthService }      from "./api.auth.service";
import { ApiCarsService }      from "./api.cars.service";
import { ApiCustomersService } from "./api.customers.service";
import { ApiDriversService }   from "./api.drivers.service";
import { ApiOrdersService }    from "./api.orders.service";
import { ApiSettingsService }  from "./api.settings.service";
import { ApiStatsService }     from "./api.stats.service";
import { ApiStationsService }  from "./api.stations.service";
import { ApiZonesService }     from "./api.zones.service";

export default class ApiService {
  constructor(endpoint) {
    if (endpoint.substr(-1) !== "/") {
      endpoint += "/";
    }

    const http = axios.create({
      baseURL: endpoint,
      withCredentials: true,
    });

    this.addresses = new ApiAddressesService(http);
    this.auth      = new ApiAuthService(http);
    this.cars      = new ApiCarsService(http);
    this.customers = new ApiCustomersService(http);
    this.drivers   = new ApiDriversService(http);
    this.orders    = new ApiOrdersService(http);
    this.settings  = new ApiSettingsService(http);
    this.stats     = new ApiStatsService(http);
    this.stations  = new ApiStationsService(http);
    this.zones     = new ApiZonesService(http);
  }
}
