import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";
import { buildRange, parseRange } from "./api.ranges";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiCarsService {
  /**
   * Creates a new instance of the API cars service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Retrieves a specific car.
   */
  async load(id) {
    try {
      const response = await this.axios.get(`cars/${id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea indicativelor.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves cars.
   */
  async list(offset, limit, search, sort) {
    try {
      const params   = { search, sort };
      const range    = buildRange(offset, limit);
      const response = await this.axios.get("cars", { params, headers: { range } });

      return {
        range: parseRange(response),
        results: response.data,
      };
    } catch (error) {
      if (error.response && error.response.status === 416) {
        return {
          range: parseRange(error.response),
          results: [],
        };
      }

      notify.error("Eroare la încărcarea indicativelor.");

      throw newApiError(error);
    }
  }

  /**
   * Enabled the specified car.
   * @param {String} id The ID of the car to enable.
   */
  async enable(id) {
    try {
      const response = await this.axios.put(`cars/${encodeURIComponent(id)}/enabled`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la activarea indicativului.");

      throw newApiError(error);
    }
  }

  /**
   * Disables the specified car.
   * @param {String} id The ID of the car to disable.
   */
  async disable(id) {
    try {
      const response = await this.axios.delete(`cars/${encodeURIComponent(id)}/enabled`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la dezactivarea indicativului.");

      throw newApiError(error);
    }
  }
}
