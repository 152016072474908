import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiSettingsService {
  /**
   * Creates a new instance of the API settings service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Get map settings.
   *
   * @returns map settings.
   */
   async getMap() {
    try {
      const response = await this.axios.get("settings/map");

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea setărilor pentru hartă.");

      throw newApiError(error);
    }
  }

  /**
   * Get order settings.
   *
   * @returns Order settings.
   */
  async getOrder() {
    try {
      const response = await this.axios.get("settings/orders");

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea setărilor.");

      throw newApiError(error);
    }
  }

  /**
   * Updates the order settings
   *
   * @param patch The patch to apply to the order settings.
   * @returns The updated order settings.
   */
  async patchOrder(patch) {
    try {
      const response = await this.axios.patch("settings/orders", patch);

      return response.data;
    } catch (error) {
      notify.error("Eroare la salvarea setărilor.");

      throw newApiError(error);
    }
  }
}
