import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */

export class ApiDriversService {
  /**
   * Creates a new instance of the API cars service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Suspends the specified driver for a limited duration.
   * @param {String} id The ID of the driver to suspend.
   * @param {String} duration The time for which to suspend the driver.
   * @param {String} reason The reason for suspension.
   */
  async suspend(id, duration, reason) {
    try {
      await this.axios.put(`drivers/${encodeURIComponent(id)}/suspension`, { duration, reason });
    } catch (error) {
      notify.error("Eroare la blocarea șoferului.");

      throw newApiError(error);
    }
  }

  /**
   * Cancels the specified driver's suspension.
   * @param {String} id The ID of the driver to reinstate.
   */
  async reinstate(id) {
    try {
      await this.axios.delete(`drivers/${encodeURIComponent(id)}/suspension`);
    } catch (error) {
      notify.error("Eroare la deblocarea șoferului.");

      throw newApiError(error);
    }
  }
}
