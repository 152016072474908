import ApiError from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */
/** @typedef {import("moment").Moment} Moment */

export class ApiStatsService {
  /**
   * Creates a new instance of the API statistics service.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(axios) {
    this.axios = axios;
  }

  /**
   * Retrieves order counts by hour for a specific date.
   * @param {Moment} date The date for which to get the statistics.
   */
  async getOrderCountsByHour(date) {
    try {
      const isoDate  = date.format("YYYY-MM-DD");
      const params   = { date: isoDate };
      const response = await this.axios.get("stats/orders/counts-hourly", { params });

      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Retrieves order cancellation reasons for a specific date.
   * @param {Moment} date The date for which to get the statistics.
   */
  async getOrderCancellationReasons(date) {
    try {
      const isoDate  = date.format("YYYY-MM-DD");
      const params   = { date: isoDate };
      const response = await this.axios.get("stats/orders/cancellation-reasons-daily", { params });

      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
